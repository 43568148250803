<template>
  <div class="table">
      <el-table
        :data="allot_goods"
        @cell-click="hanldeCellClick"
        :cell-class-name="getCellIndex"
      >
        <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
        <template v-for="(item, index) in rowList">
          <el-table-column
            align="center"
            :key="index"
            :prop="item.prop"
            :label="item.label"
            :width="item.prop === 'goods_number'? 300 : ''"
          >
            <template slot-scope="scope">
              <template v-if="(item.prop === 'num' &&
                        scope.row.goods_id && scope.row.goods_number_type === 20) ||
                        (item.prop === 'goods_number' && !scope.row.goods_id)">
                <el-input
                  clearable
                  autofocus="true"
                  :id="'input' + scope.row.index + scope.column.index"
                  v-model="scope.row[item.prop]"
                  :placeholder="item.prop === 'goods_number'? '请输入条码号' : ''"
                  v-show="
                    coordinate.column === scope.column.index &&
                    coordinate.row === scope.row.index
                  "
                  @keyup.enter.native="handleKeydown($event, scope.row[item.prop], item.prop, scope.row.index, scope.column.index, scope.row.num)"
                >
                </el-input>
                <span
                  v-show="
                    scope.row.index !== coordinate.row ||
                    scope.column.index !== coordinate.column
                  "
                >
                  {{ scope.row[item.prop] }}
                  <span v-if="!scope.row[item.prop]" style="color: #ccc;">{{ item.prop === 'goods_number'? '请点击输入条码号，按下Enter键结束' : '' }}</span>
                </span>
              </template>
              <template v-else>
                {{ item.prop === 'goods_number_type'?
                (scope.row[item.prop] === 10? '一码一货': scope.row[item.prop] === 20? '一码多货': '')
                :scope.row[item.prop] }}
              </template>
            </template>
          </el-table-column>
        </template>
        <el-table-column
          align="center"
          fixed="right"
          prop="operation"
          label="操作"
          width="180"
        >
          <template slot-scope="scope">
            <div class="options">
              <el-button type="text" v-if="scope.row.goods_id" @click="handleDele(scope.row.goods_number)">删除</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="total">
        <div class="total-left">合计</div>
        <div class="total-right">
          <div class="total-right-item">调拨数量：<span>{{total || '0'}}</span></div>
          <div class="total-right-item">金重(g)：<span>{{weight || '0.00'}}</span></div>
          <div class="total-right-item">标签价：<span>{{sale_price || '0.0000'}}</span></div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'Add',
  props: {
    allot_goods: {
      type: Array,
      default() {
        return [];
      },
    },
    total: String,
    weight: String,
    sale_price: String,
  },
  data() {
    return {
      rowList: [
        {
          prop: "index",
          label: "序号",
        },
        {
          prop: "goods_number",
          label: "货号",
        },
        {
          prop: "goods_name",
          label: "品名",
        },
        {
          prop: "101",
          label: "品类",
        },
        {
          prop: "102",
          label: "产地",
        },
        {
          prop: "103",
          label: "品牌",
        },
        {
          prop: "104",
          label: "金重",
        },
        {
          prop: "sale_price",
          label: "标签价",
        },
        {
          prop: "goods_number_type",
          label: "货品类型",
        },
        {
          prop: "num",
          label: "调拨数量",
        },
      ],
      coordinate: { column: -1, row: -1 },
    };
  },
  methods: {
    handleDele() {},
    getCellIndex({ row, column, rowIndex, columnIndex }) {
      row.index = rowIndex + 1;
      column.index = columnIndex;
    },
    hanldeCellClick(row, column) {
      const coordinate = { ...this.coordinate };
      coordinate.row = row.index;
      coordinate.column = column.index;
      this.coordinate = coordinate;
      const dom = document.getElementById('input' + row.index + column.index);
      this.$nextTick(() => {
        try {
          dom.focus();
        } catch (error) {
          // console.log('无法聚焦');
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.total {
  display: flex;
  justify-content: space-between;
  line-height: 42px;
  padding: 0 20px;
  background: linear-gradient(0deg, #f3f9ff, #ffffff);
  border: 1px solid #ddd;
  border-top: none;
  border-radius: 0px 0px 6px 6px;
  &-left {
  }
  &-right {
    display: flex;
    &-item {
      font-size: 14px;
      padding: 0 10px;
      span {
        color: #fd563a;
        font-weight: bold;
      }
    }
  }
}
</style>
