import { post } from '@/utils/request';

// 调拨详情
const detailReq = (allot_id) => post({
  url: '/store/goods.Allot/detail',
  data: {
    allot_id,
  },
});

// 反确认商品详情
const resetDetailGoodsListReq = (params) => post({
  url: '/store/goods.Allot/resetDetailGoodsList',
  data: {
    ...params,
  },
});

// 调拨详情--商品列表
const detailGoodsListReq = ({ allot_id, page, page_num }) => post({
  url: '/store/goods.Allot/detailGoodsList',
  data: {
    allot_id,
    page,
    page_num,

  },
});

const detailCheckGoodsList = (data) => post({
  url: '/store/goods.Allot/detailCheckGoodsList',
  data: data
})

// 操作日志
const getLogListReq = (params) => post({
  url: '/store/goods.Allot/getLogList',
  data: {
    ...params,
  },
});

// 调拨单核对数据
const checkOperation = (data) => post({
  url: '/store/goods.Allot/checkOperation',
  data: data
});

// 调拨单修改数据
const allotUpdate = (data) => post({
  url: '/store/goods.Allot/allotUpdate',
  data: data
});

// 调拨核对查询货品信息
const getGoodsFind = (data) => post({
  url: '/store/goods.Allot/getGoodsFind',
  data: data
})

export {
  detailReq,
  detailGoodsListReq,
  getLogListReq,
  checkOperation,
  allotUpdate,
  getGoodsFind,
  detailCheckGoodsList,
  resetDetailGoodsListReq,
};
