var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table"},[_c('el-table',{attrs:{"data":_vm.allot_goods,"cell-class-name":_vm.getCellIndex},on:{"cell-click":_vm.hanldeCellClick}},[_vm._l((_vm.rowList),function(item,index){return [_c('el-table-column',{key:index,attrs:{"align":"center","prop":item.prop,"label":item.label,"width":item.prop === 'goods_number'? 300 : ''},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [((item.prop === 'num' &&
                      scope.row.goods_id && scope.row.goods_number_type === 20) ||
                      (item.prop === 'goods_number' && !scope.row.goods_id))?[_c('el-input',{directives:[{name:"show",rawName:"v-show",value:(
                  _vm.coordinate.column === scope.column.index &&
                  _vm.coordinate.row === scope.row.index
                ),expression:"\n                  coordinate.column === scope.column.index &&\n                  coordinate.row === scope.row.index\n                "}],attrs:{"clearable":"","autofocus":"true","id":'input' + scope.row.index + scope.column.index,"placeholder":item.prop === 'goods_number'? '请输入条码号' : ''},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.handleKeydown($event, scope.row[item.prop], item.prop, scope.row.index, scope.column.index, scope.row.num)}},model:{value:(scope.row[item.prop]),callback:function ($$v) {_vm.$set(scope.row, item.prop, $$v)},expression:"scope.row[item.prop]"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(
                  scope.row.index !== _vm.coordinate.row ||
                  scope.column.index !== _vm.coordinate.column
                ),expression:"\n                  scope.row.index !== coordinate.row ||\n                  scope.column.index !== coordinate.column\n                "}]},[_vm._v(" "+_vm._s(scope.row[item.prop])+" "),(!scope.row[item.prop])?_c('span',{staticStyle:{"color":"#ccc"}},[_vm._v(_vm._s(item.prop === 'goods_number'? '请点击输入条码号，按下Enter键结束' : ''))]):_vm._e()])]:[_vm._v(" "+_vm._s(item.prop === 'goods_number_type'? (scope.row[item.prop] === 10? '一码一货': scope.row[item.prop] === 20? '一码多货': '') :scope.row[item.prop])+" ")]]}}],null,true)})]}),_c('el-table-column',{attrs:{"align":"center","fixed":"right","prop":"operation","label":"操作","width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"options"},[(scope.row.goods_id)?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.handleDele(scope.row.goods_number)}}},[_vm._v("删除")]):_vm._e()],1)]}}])})],2),_c('div',{staticClass:"total"},[_c('div',{staticClass:"total-left"},[_vm._v("合计")]),_c('div',{staticClass:"total-right"},[_c('div',{staticClass:"total-right-item"},[_vm._v("调拨数量："),_c('span',[_vm._v(_vm._s(_vm.total || '0'))])]),_c('div',{staticClass:"total-right-item"},[_vm._v("金重(g)："),_c('span',[_vm._v(_vm._s(_vm.weight || '0.00'))])]),_c('div',{staticClass:"total-right-item"},[_vm._v("标签价："),_c('span',[_vm._v(_vm._s(_vm.sale_price || '0.0000'))])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }