<template>
  <div>
    <div class="content">
      <div class="card">
        <el-page-header
          @back="goBack"
          :content="allot_info.allot_status === 30 ? '调拨核对' : '核对详情'"
        ></el-page-header>
      </div>
    </div>
    <div class="newForm" style="padding-bottom: 20px">
      <div class="title">
        <div class="span"></div>
        核对汇总
      </div>
      <div class="cutline"></div>
      <div class="speed">
        <div class="box boxOne">
          <div class="speedNum">{{ allot_info.allot_total_num || 0 }}</div>
          <div class="speedText">货品数量</div>
        </div>
        <div class="box boxSix">
          <div class="speedNum">
            {{ allot_info.total_weight || 0 }}
          </div>
          <div class="speedText">货品总重(g)</div>
        </div>
        <div class="box boxSix">
          <div class="speedNum">{{ allot_info.total_goldweight || 0 }}</div>
          <div class="speedText">货品净金重(g)</div>
        </div>
        <div class="box boxSix">
          <div class="speedNum">{{ allot_info.allot_check_num || 0 }}</div>
          <div class="speedText">已核数量</div>
        </div>
        <div class="box boxSix">
          <div class="speedNum">
            {{ allot_info.allot_check_goods_weight || 0 }}
          </div>
          <div class="speedText">已核总重(g)</div>
        </div>
        <div class="box boxThree">
          <div class="speedNum">
            {{ allot_info.allot_check_gold_weight || 0 }}
          </div>
          <div class="speedText">已核净金重(g)</div>
        </div>
        <div class="box boxFour">
          <div class="speedNum">
            {{ allot_info.allot_total_num - allot_info.allot_check_num || 0 }}
          </div>
          <div class="speedText">未核数量</div>
        </div>
        <div class="box boxThree">
          <div class="speedNum">
            {{
              allot_info.total_weight - allot_info.allot_check_goods_weight || 0
            }}
          </div>
          <div class="speedText">未核总重(g)</div>
        </div>
        <div class="box boxThree">
          <div class="speedNum">
            {{
              allot_info.total_goldweight -
                allot_info.allot_check_gold_weight || 0
            }}
          </div>
          <div class="speedText">未核净金重(g)</div>
        </div>
      </div>
    </div>
    <div class="newForm" v-if="allot_info.allot_status === 30">
      <div class="title">
        <div class="span"></div>
        核对货品
      </div>
      <div class="cutLine"></div>
      <div class="result" style="padding-bottom: 20px">
        <div class="resultBox">
          <div class="resultInput">
            <el-input
              placeholder="扫描条码，鼠标光标请点这里"
              v-model="goodsValue"
              id="numberInput"
              @keyup.enter.native="handleKeydown"
            />
            <div>
              <el-button type="primary" @click="handleKeydown">提交</el-button>
            </div>
          </div>
          <div class="resutlImg">
            <img :src="goodsInfo.goods_pic ? goodsInfo.goods_pic : no_img" />
          </div>
        </div>

        <div class="resutlText">
          <el-row>
            <el-col :span="8">
              <div class="item">
                <div class="itemLeft">货号</div>
                <div class="itemRight">
                  {{ goodsInfo.goods_number || "--" }}
                </div>
              </div>
            </el-col>
            <el-col :span="8"
              ><div class="item">
                <div class="itemLeft">仓库</div>
                <div class="itemRight">
                  {{ goodsInfo.warehouse_name || "--" }}
                </div>
              </div></el-col
            >
            <el-col :span="8"
              ><div class="item">
                <div class="itemLeft">品牌</div>
                <div class="itemRight">{{ goodsInfo[103] || "--" }}</div>
              </div></el-col
            >
            <el-col :span="8"
              ><div class="item">
                <div class="itemLeft">入库时间</div>
                <div class="itemRight">
                  {{ goodsInfo.create_time || "--" }}
                </div>
              </div></el-col
            >
            <el-col :span="8"
              ><div class="item">
                <div class="itemLeft">标签价</div>
                <div class="itemRight">
                  {{ goodsInfo.sale_price || "--" }}
                </div>
              </div></el-col
            >

            <el-col :span="8"
              ><div class="item">
                <div class="itemLeft">供应商</div>
                <div class="itemRight">
                  {{ goodsInfo.supplier_name || "--" }}
                </div>
              </div></el-col
            >
            <el-col :span="8"
              ><div class="item">
                <div class="itemLeft">入库单号</div>
                <div class="itemRight">
                  {{ goodsInfo.entry_number || "--" }}
                </div>
              </div></el-col
            >
            <el-col :span="8"
              ><div class="item">
                <div class="itemLeft">品类</div>
                <div class="itemRight">
                  {{ goodsInfo.goods_type_name || "--" }}
                </div>
              </div></el-col
            >
            <el-col :span="8"
              ><div class="item">
                <div class="itemLeft">RFID</div>
                <div class="itemRight">{{ goodsInfo.rfid_number || "--" }}</div>
              </div></el-col
            >
            <el-col :span="8"
              ><div class="item">
                <div class="itemLeft">入库备注</div>
                <div class="itemRight">{{ goodsInfo.entry_note || "--" }}</div>
              </div></el-col
            >
            <el-col :span="8"
              ><div class="item">
                <div class="itemLeft">款号</div>
                <div class="itemRight">
                  {{ goodsInfo.goods_style_number || "--" }}
                </div>
              </div></el-col
            >
            <el-col :span="8"
              ><div class="item">
                <div class="itemLeft">金重(g)</div>
                <div class="itemRight">{{ goodsInfo[104] || "--" }}</div>
              </div></el-col
            >
            <el-col :span="8"
              ><div class="item">
                <div class="itemLeft">证书类型</div>
                <div class="itemRight">{{ goodsInfo[111] || "--" }}</div>
              </div></el-col
            >
            <el-col :span="8"
              ><div class="item">
                <div class="itemLeft">款式</div>
                <div class="itemRight">{{ goodsInfo[101] || "--" }}</div>
              </div></el-col
            >
            <el-col :span="8"
              ><div class="item">
                <div class="itemLeft">总重(g)</div>
                <div class="itemRight">{{ goodsInfo[110] || "--" }}</div>
              </div></el-col
            >
            <el-col :span="8"
              ><div class="item">
                <div class="itemLeft">证书号</div>
                <div class="itemRight">{{ goodsInfo[112] || "--" }}</div>
              </div></el-col
            >
            <el-col :span="8"
              ><div class="item">
                <div class="itemLeft">材质成色</div>
                <div class="itemRight">{{ goodsInfo[100] || "--" }}</div>
              </div></el-col
            >
          </el-row>
        </div>
      </div>
    </div>
    <div class="newForm">
      <div class="title">
        <div class="span"></div>
        核对信息
      </div>
      <div class="cutline"></div>
      <div class="Intable">
        <el-radio-group
          v-model="tabPosition"
          style="margin-bottom: 30px"
          @change="handleTab"
        >
          <el-radio-button label="">全部</el-radio-button>
          <el-radio-button :label="20">已核</el-radio-button>
          <el-radio-button :label="10">未核</el-radio-button>
        </el-radio-group>
      </div>
      <div class="Intable">
        <el-table :data="goodsList" stripe :height="350">
          <el-table-column type="index" align="center" label="序号">
          </el-table-column>
          <el-table-column
            align="center"
            prop="goods_number"
            label="货号"
            width="200"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="goods_name"
            label="品名"
            width="200"
          >
            <template slot-scope="scope">
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                {{ scope.row.goods_name || "--" }}
                <template v-for="item in scope.row.goods_tag_pic">
                  <img :src="item" :key="item" style="margin-right: 5px" />
                </template>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="goods_number_type"
            label="货品类型"
          >
            <template slot-scope="scope">
              <div>
                {{
                  scope.row.goods_number_type === 10 ? "一码一货" : "一码多货"
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="original_gold_weight"
            label="应核净金重(g)"
            width="150"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="practical_gold_weight"
            label="实核净金重(g)"
            width="150"
          >
            <template slot-scope="scope">
              <div>
                <div v-if="!scope.row.isGoldWeight">
                  {{ scope.row.practical_gold_weight || 0 }}
                  <el-button
                    v-if="allot_info.allot_status === 30 && tabPosition === 20"
                    type="text"
                    @click="
                      () => {
                        scope.row.isGoldWeight = true;
                      }
                    "
                    >修改</el-button
                  >
                </div>
                <div v-if="scope.row.isGoldWeight">
                  <el-input
                    style="width: 50%; margin-right: 8px"
                    v-model="scope.row.practical_gold_weight"
                  />
                  <el-button type="text" @click="allotUpdate(scope.row, 1)"
                    >保存</el-button
                  >
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="gain_gold_weight"
            label="差异净金重(g)"
            width="150"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="original_goods_weight"
            label="应核总重(g)"
            width="150"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="practical_goods_weight"
            label="实核总重(g)"
            width="150"
          >
            <template slot-scope="scope">
              <div>
                <div v-if="!scope.row.isGoodsWeightRewind">
                  {{ scope.row.practical_goods_weight || 0 }}
                  <el-button
                    v-if="allot_info.allot_status === 30 && tabPosition === 20"
                    type="text"
                    @click="
                      () => {
                        scope.row.isGoodsWeightRewind = true;
                      }
                    "
                    >修改</el-button
                  >
                </div>
                <div v-if="scope.row.isGoodsWeightRewind">
                  <el-input
                    style="width: 50%; margin-right: 8px"
                    v-model="scope.row.practical_goods_weight"
                  />
                  <el-button type="text" @click="allotUpdate(scope.row, 2)"
                    >保存</el-button
                  >
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="gain_goods_weight"
            label="差异总重(g)"
            width="150"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="original_total_num"
            label="应核数量"
            width="150"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="practical_total_num"
            label="实核数量"
            width="150"
          >
            <template slot-scope="scope">
              <div>
                <div v-if="!scope.row.isTotalNumRewind">
                  {{ scope.row.practical_total_num || 0 }}
                  <el-button
                    v-if="
                      allot_info.allot_status === 30 &&
                      scope.row.goods_number_type === 20 &&
                      tabPosition === 20
                    "
                    type="text"
                    @click="
                      () => {
                        scope.row.isTotalNumRewind = true;
                      }
                    "
                    >修改</el-button
                  >
                </div>
                <div v-if="scope.row.isTotalNumRewind">
                  <el-input
                    style="width: 50%; margin-right: 8px"
                    v-model="scope.row.practical_total_num"
                  />
                  <el-button type="text" @click="allotUpdate(scope.row, 3)"
                    >保存</el-button
                  >
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="gain_total_num"
            label="差异数量"
            width="180"
          >
          </el-table-column>
          <el-table-column align="center" prop="sale_price" label="标签价">
          </el-table-column>
          <el-table-column
            align="center"
            prop="original_total_price"
            label="应核总标签价(元)"
            width="150"
          >
          </el-table-column>

          <el-table-column
            align="center"
            prop="practical_total_price"
            label="实核总标签价(元)"
            width="150"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="gain_total_price"
            label="差异总标签价(元)"
            width="180"
          >
          </el-table-column>
          <el-table-column align="center" prop="is_check" label="核对结果">
            <template slot-scope="scope">
              <div style="color: #21bc36">
                {{ scope.row.is_check === 10 ? "未核对" : "已核对" }}
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagina">
          <el-pagination
            :current-page="page"
            background
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            :page-size="$store.state.pageSizes[0]"
            :page-sizes="$store.state.pageSizes"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          ></el-pagination>
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="auditVisiable"
      width="30%"
      title="填写货品信息"
      :destroy-on-close="true"
      :before-close="cancelAudit"
    >
      <el-form ref="auditForm" :model="auditForm" label-width="120px">
        <el-form-item label="货品数量(件)" prop="remark">
          <el-input
            :rows="2"
            placeholder="请输入货品数量"
            v-model="auditForm.practical_total_num"
          ></el-input>
        </el-form-item>
        <el-form-item label="货品净金重(g)" prop="remark">
          <el-input
            :rows="2"
            placeholder="请输入货品净金重(g)"
            v-model="auditForm.practical_gold_weight"
          ></el-input>
          <span style="color: #fd563a">单个货品的净金重(g)</span>
        </el-form-item>
        <div style="text-align: right">
          <el-button type="primary" @click="submitAudit()">提交</el-button>
          <el-button @click="cancelAudit">取消</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  detailGoodsListReq,
  checkOperation,
  allotUpdate,
  getGoodsFind,
  detailCheckGoodsList,
} from "@/api/goods/goodsTransfer/detail.js";
import { detailReq } from "@/api/goods/goodsTransfer/detail.js";
import no_img from "../../../assets/images/no_img.png";
import moment from "moment";

export default {
  data() {
    return {
      auditVisiable: false,
      info: {},
      goodsValue: "",
      goodsInfo: {},
      goodsList: [],
      total: 0,
      page: 1,
      page_num: 50,
      tabPosition: "",
      auditForm: {},
      no_img: no_img,
      allot_info: {},
    };
  },
  props: {
    allot_id: Number,
  },
  created() {
    this.getDetail();
    this.detailsList();
  },
  methods: {
    /*
     *  获取详情
     */
    getDetail() {
      detailReq(this.allot_id).then((res) => {
        if (res.code === 1) {
          this.allot_info = res.data;
        }
      });
    },
    goBack() {
      this.$emit("hide");
    },
    cancelAudit() {
      this.auditVisiable = false;
      this.goodsValue = "";
      this.auditForm = {};
    },
    /*
     * 修改数据
     */
    allotUpdate(data, type) {
      const info = {
        allot_id: this.allot_id,
        allot_goods_id: data.allot_goods_id,
        practical_total_num: Number(data.practical_total_num),
        practical_goods_weight: data.practical_goods_weight,
        practical_gold_weight: data.practical_gold_weight,
      };
      allotUpdate(info).then((res) => {
        if (res.code === 1) {
          this.$message({
            type: "success",
            message: "保存成功!",
          });
          this.detailsList();
          this.getDetail();
        }
      });
    },
    /*
     *  根据条形码获取数据
     */
    handleKeydown() {
      this.goodsInfo = {};

      // 如果有中文
      const pattern = new RegExp("[\u4E00-\u9FA5]+");
      if (pattern.test(this.goodsValue)) {
        this.$message.error("不能存在中文");
        return;
      }
      getGoodsFind({
        goods_number: this.goodsValue,
        allot_number: this.allot_info.allot_number,
        allot_id: this.allot_id,
      }).then((res) => {
        if (res.code === 1) {
          if (res.data !== {}) {
            this.goodsInfo = res.data;
            this.goodsInfo.entry_create_time = moment(
              this.goodsInfo.entry_create_time * 1000
            ).format("YYYY-MM-DD HH:mm:ss");
          } else {
            this.$message({
              type: "error",
              message: "查询不到数据",
            });
          }
        }
      });
      this.handleCheckOperation();
    },
    /*
     *  提交货号校验是否为一码多货/一码一货
     */
    handleCheckOperation() {
      const data = {
        allot_id: this.allot_id,
        goods_number: this.goodsValue,
      };
      if (this.goodsInfo !== "{}") {
        if (this.goodsInfo.goods_number_type === 10) {
          data.practical_gold_weight = this.goodsInfo.goods_gold_weight || 0;
          data.practical_total_num = this.goodsInfo.num || 1;
        } else if (this.goodsInfo.goods_number_type === 20) {
          this.auditVisiable = true;
        }
      }
      this.handleCommitTak(data);
    },
    submitAudit() {
      const data = {
        allot_id: this.allot_id,
        goods_number: this.goodsValue,
      };
      data.practical_gold_weight = this.auditForm.practical_gold_weight;
      data.practical_total_price = this.goodsInfo.sale_price;
      data.practical_total_num = this.auditForm.practical_total_num;
      data.is_adopt = 10;
      this.handleCommitTak(data);
    },
    handleCommitTak(data) {
      checkOperation(data)
        .then((res) => {
          if (res.code === 1) {
            this.$message({
              type: "success",
              message: "提交成功!",
            });
            this.goodsValue = "";
            this.auditVisiable = false;
            this.tabPosition = 20;
            this.auditForm = {};
            this.page = 1;
            this.getDetail();
            this.detailsList();
          } else if (res.code === 206) {
            this.auditVisiable = true;
          } else {
            this.auditVisiable = false;
            this.goodsValue = "";
          }
        })
        .catch((res) => {
          if (res.code === 206) {
            this.auditVisiable = false;
          }
        });
    },
    handleCurrentChange() {},
    handleSizeChange() {},
    handleTab(event) {
      this.page = 1;
      this.detailsList();
    },
    /*
     *  核对详情
     */
    detailsList() {
      const data = {
        allot_id: this.allot_id,
        page_num: this.page_num,
        page: this.page,
        is_check: this.tabPosition,
      };
      detailCheckGoodsList(data).then((res) => {
        if (res.code === 1) {
          res.data.list.forEach((item) => {
            item.isGoldWeight = false;
            item.isGoodsWeightRewind = false;
            item.isTotalNumRewind = false;
            return item;
          });
          this.goodsList = res.data.list;
          this.total = res.data.total;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  display: flex;
  flex-direction: row;
  padding-left: 30px;
  height: 60px;
  line-height: 60px;

  .span {
    margin: auto 10px auto 0;
    width: 4px;
    height: 16px;
    background: #5493f5;
    border-radius: 2px;
  }
}
.speed {
  margin-left: 20px;
  display: flex;
  flex-direction: row;
}
.newly {
  padding: 20px;
}
.box {
  margin-left: 10px;
  width: 185px;
  height: 90px;
  border-radius: 6px;
  color: white;
  text-align: center;
}
.boxOne {
  background: linear-gradient(90deg, #5aabfe, #597bf7);
}
.boxTwo {
  background: linear-gradient(90deg, #5aabfe, #597bf7);
}
.boxThree {
  background: linear-gradient(90deg, #fd7e5d, #ff628a);
}
.boxFour {
  background: linear-gradient(90deg, #fd7e5d, #ff628a);
}
.boxFive {
  background: linear-gradient(
    90deg,
    rgba(14, 205, 186, 0.98),
    rgba(0, 190, 244, 0.98)
  );
}
.boxSix {
  background: linear-gradient(
    90deg,
    rgba(14, 205, 186, 0.98),
    rgba(0, 190, 244, 0.98)
  );
}
.boxSeven {
  background: linear-gradient(
    90deg,
    rgba(56, 89, 236, 0.98),
    rgba(28, 156, 216, 0.98)
  );
}
.speedNum {
  margin-top: 15px;
  font-size: 28px;
  font-family: Microsoft YaHei;
  font-weight: bold;
}
.speedText {
  font-size: 16px;
}
.speedRight {
  margin: auto 10px auto auto;
}
.cutLine {
  width: 100%;
  height: 1px;
  background: #f5f6fa;
}
.result {
  display: flex;
  margin: 20px 0 0 20px;
}
.resultBox {
  padding: 10px;
  width: 300px;
}
.resultInput {
  display: flex;
  margin-bottom: 5px;
  width: 300px;
  background: #ffffff;
}

.resutlImg {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  width: 300px;
  height: 200px;
  border: 1px solid #ddd;
  border-radius: 6px;

  img {
    height: 100%;
  }
}
.Intable {
  padding: 0 20px 0 20px;
}
.resutlText {
  width: 80%;
  margin-left: 30px;
  padding: 10px;
}
.item {
  display: flex;
  margin-left: 30px;
  font-size: 14px;

  .itemLeft {
    width: 35%;
    height: 45px;
    color: #909399;
  }
  .itemRight {
    width: 55%;
  }
}
.image {
  width: 54px;
  height: 54px;
  margin: 5px auto;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>