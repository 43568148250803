import { post } from '@/utils/request.js';

const auditReq = ({ allot_id, allot_status, audit_content }) => post({
  url: '/store/goods.Allot/audit',
  data: {
    allot_id,
    allot_status,
    audit_content,
  },
});

const recipientReq = ({ allot_id, allot_status, recipient_content, warehouse_id }) => post({
  url: '/store/goods.Allot/recipient',
  data: {
    allot_id,
    allot_status,
    recipient_content,
    warehouse_id,
  },
});

export {
  auditReq,
  recipientReq,
};
