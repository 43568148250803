import { post } from '@/utils/request';

const listReq = (cond) => post({
  url: '/store/goods.AllotWarehouse/list',
  data: {
    ...cond,
  },
});

// 导出
const exportReq = (cond) => post({
  url: '/store/goods.Allot/download',
  data: {
    ...cond,
  },
});

// 调拨单打印数据
const allotPrintReq = (cond) => post({
  url: '/store/goods.AllotWarehouse/allotPrint',
  data: {
    ...cond,
  },
});

// 调拨反确认删除
const resetDeleteReq = (cond) => post({
  url: '/store/goods.Allot/resetDelete',
  data: {
    ...cond,
  },
});

// 调拨撤销
const recallReq = (cond) => post({
  url: '/store/goods.Allot/recall',
  data: {
    ...cond,
  },
});

// 调拨被驳回删除
const deleteReq = (cond) => post({
  url: '/store/goods.Allot/delete',
  data: {
    ...cond,
  },
});

export {
  deleteReq,
  listReq,
  exportReq,
  allotPrintReq,
  resetDeleteReq,
  recallReq,
};
